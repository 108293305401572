<template>
	<div class="login">
		<div class="logo text-center">
			<a href="https://www.dentroeduca.com.br/" target="_blank"
				><img
					width="127"
					:src="require('@/assets/primary-1.svg')"
					alt="Dentro Educa"
					class
			/></a>
		</div>

		<h3>Escolha a sua opção</h3>
		<h5>Faça seu login para acessar a área desejada.</h5>

		<div class="va-row">
			<router-link
				id="btn-access-manager"
				:to="{ name: 'login-manager' }"
				class="btn btn-primary orange"
				target="_blank"
			>
				Secretaria da Educação
			</router-link>

			<hr />

			<router-link
				id="btn-access-diretor"
				:to="{ name: 'login-diretor' }"
				class="btn btn-primary purple"
				target="_blank"
			>
				Gestor Escolar
			</router-link>
			<router-link
				id="btn-access-teacher"
				:to="{ name: 'login-professor' }"
				class="btn btn-primary green"
				target="_blank"
			>
				Professor
			</router-link>
			<router-link
				id="btn-access-student"
				:to="{ name: 'login-child' }"
				class="btn btn-primary blue-light"
				target="_blank"
			>
				Aluno
			</router-link>
		</div>
	</div>
</template>

<script>
	import { mask } from "vue-the-mask";
	const axios = require("axios");

	export default {
		name: "login-user",

		data() {
			return {
				showSchools: false,
				schoolList: [],

				login: {
					username: null,
					user_temporary_id: null,
					school_id: null,
				},

				sendActive: true,
			};
		},

		mounted() {
			const self = this;

			this.$store.commit("CLEAR_SESSIONS");
		},

		methods: {
			doLogin() {
				const self = this;

				this.$validator.validateAll().then((result) => {
					if (result) {
						//self.login.username = self.login.username + "@dentrodaescola.com.br"

						axios
							.post(
								process.env.VUE_APP_ROOT_API + "/students/step_2",
								self.login
							)
							.then(function (response) {
								let studentCredential = {
									token: response.data.data.token,
									user: response.data.data.user,
									main_product: response.data.data.main_product,
								};

								self.$store.commit(
									"SAVE_STUDENT_CREDENTIALS",
									studentCredential
								);
								self.$router.push({ name: "student-step-1" });
							})
							.catch(function (error) {
								// console.log(error)

								let fieldError = {
									field: "error",
									msg: error.response.data.message,
									rule: "error", // optional
								};
								self.$validator.errors.add(fieldError);
							});
					}
				});
			},

			created: function () {
				const self = this;

				// if (this.login.username.length === 11) {

				//let login = this.login.username + "@dentrodahistoria.com.br"

				axios
					.post(process.env.VUE_APP_ROOT_API + "/students/login", self.login)
					.then((res) => {
						self.schoolList = self.createListOfSchools(res.data.data.schools);
						self.login.user_temporary_id = res.data.data.user_temporary_id;
						self.showSchools = true;
					})
					.catch((error) => {
						this.openAddressField = true;
						self.showSchools = false;

						let fieldError = {
							field: "error",
							msg: error.response.data.message,
							rule: "error", // optional
						};
						self.$validator.errors.add(fieldError);
					});
				//}
			},

			createListOfSchools(schoolList) {
				let list = [];

				schoolList.forEach((element) => {
					var item = { key: element.id, value: element.name };
					list.push(item);
				});

				return list;
			},

			onChange(event) {
				this.login.school_id = event.target.value;
				this.sendActive = false;
				//this.address = event.target.value
			},

			updateAndVerifyCep({ e, target }) {
				this.login.username = target.value;
				this.created();
			},
		},
	};
</script>

<style lang="scss">
	hr {
		width: 100%;
		margin: 0 auto;
		margin-top: 30px;
	}
	.select-group {
		width: 100%;
	}
	.select {
		max-width: 100%;
		box-sizing: border-box;
		-ms-touch-action: manipulation;
		-webkit-appearance: none;
		touch-action: manipulation;
		margin-bottom: 0;
		z-index: 1;
		transition: all 150ms ease;
		padding: 4px 4px;
		display: block;
		width: 100%;
		background-color: transparent;
		height: 65px;
		border-radius: 5px;
		border: solid 1px #dfdae4;
		font-family: Roboto;
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #000000;
		padding-left: 20px;
		width: 100%;
	}
	.recover-link {
		position: absolute;
		right: 20px;
		top: 35px;
		width: 150px;

		.btn {
			width: 100%;
		}
	}
	.login {
		font-family: "Nunito";
		width: 35rem;
		text-align: center;

		@media (max-height: 663px) {
			height: inherit;
		}

		@include media-breakpoint-down(md) {
			width: 100%;
			padding-right: 2rem;
			padding-left: 2rem;
			.down-container {
				display: none;
			}
		}

		h3 {
			text-align: center;
			font-size: 24px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.17;
			letter-spacing: 1.33px;
			text-align: center;
			color: #68596e;
			font-weight: 800;
			margin-bottom: 10px;
		}

		h5 {
			font-size: 18px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #afa2ba;
			margin-bottom: 30px;
		}

		.btn-primary {
			width: 100%;
			//font-family: "Nunito";
			font-size: 14px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 4;
			letter-spacing: 0.8px;
			text-align: center;
			color: #ffffff;
			border-radius: 40px;
			text-transform: uppercase;
			margin-top: 30px;
		}
		.down-container {
			margin-top: 3.125rem;
		}
	}

	.blue {
		background-image: none !important;
	}

	.orange {
		background-image: none !important;
		background-color: #fcae00 !important;
		&:hover {
			background-color: #f98e00 !important;
			transition: 0.5s;
		}
	}
	.purple {
		background-image: none !important;
		background-color: #923fda !important;
		&:hover {
			background-color: #8339c4 !important;
			transition: 0.5s;
		}
	}
	.green {
		background-image: none !important;
		background-color: #00cf72 !important;
		&:hover {
			background-color: #008a4c !important;
			transition: 0.5s;
		}
	}
	.blue-light {
		background-image: none !important;
		background-color: #16d3db !important;
		&:hover {
			background-color: #17b8bc !important;
			transition: 0.5s;
		}
	}
</style>
